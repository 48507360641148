import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import titleMixin from './mixins/titleMixin';
import variableMixin from './mixins/variableMixin';
import axios from 'axios';

createApp(App)
  .use(store)
  .use(router)
  .use({
    install(app) {
      app.config.globalProperties.$http = axios.create({
        baseURL: process.env.BASE_URL + 'api/',
        validateStatus: null,
      });
    }
  })
  .mixin(titleMixin)
  .mixin(variableMixin)
  .mount('#app');
