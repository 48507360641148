<template>
  <header>
    <div class="brand">
      <router-link to="/">
        <img :src="`${baseUrl}img/logo.png`" />
        <span>
          <img :src="`${baseUrl}img/logo2.png`" />
        </span>
      </router-link>
    </div>
    <nav-bar />
  </header>
  <section class="main">
    <router-view />
  </section>
  <footer>
    Copyright ⓒ2024 Augmented Knowledge., Licensed to Keyup corp. all rights reserved.
  </footer>
</template>

<script>
import NavBar from '@/components/NavBar.vue';

export default {
  name: "app",
  components: {
    NavBar,
  },
  computed: {
    debug: function () {
      return false;
    },
    user: {
      get() {
        return this.$store.state.user;
      },
      set(value) {
        this.$store.commit('setUser', value);
      }
    }
  },
  created: async function () {
    const res = await this.$http.get('profile');
    if (res.status <= 299) {
      this.user = res.data;
    }
  }
}
</script>

<style lang="scss">
/* header */
header {
  width: 100vw;
  background: #0E1128;
  height: 60px;
  position: fixed;
  z-index: 99;
  display: flex;
  justify-content: space-between;
  align-items: center;


  .brand {

    margin: 0;

    span {
      margin-left: 15px;
    }

    img {
      vertical-align: middle;
    }

    h1 {
      display: inline-block;
      margin-left: 10px;
      font-size: 20px;
      font-weight: 700;
      color: #fff;
    }
  }
}

.main {
  position: relative;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100vh;
}

footer {
  position: fixed;
  width: 100vw;
  top: calc(100vh - 70px);
  height: 70px;
  color: #fff;
  background-color: #0E1128;
  line-height: 70px;
  margin-top: 0px;
  text-align: center;
  z-index: 1000;
}

/* variables */
:root {
  --right-pane-width: 400px;
  --annotation-preview-width: 50vw;
  --annotation-preview-height: 50vh;
}
</style>
