<template>
  <div class="home">
    <div class="black-cover"></div>
    <div class="title-wrap">
      <div class="title-box">
        <span class="typing-txt">ANNOTATION DATA LABELING</span>
      </div>
      <p>KEYOPS와 ANNOTATION의 만남! 지금 바로 확인해보세요.<br>
어노테이션으로 정확도가 높은 인터랙티브 영상을 제작할 수 있습니다.</p>
    </div>
    <video loop="true" autoplay="true" muted="muted">
      <source :src="`${baseUrl}video/main_bg.mp4`" type="video/mp4" />
    </video>
  </div>
</template>

<script>
export default {
  name: 'home',
  title: 'AK Annotation Tool',
  components: {
  },
}
</script>

<style lang="scss" scoped>
.home {
  position: relative;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;


  video{
    width: 100%;
  }

  .black-cover {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.15)
  }

  .title-wrap {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    text-align: center;
    text-shadow: 1px 2px 2px rgba(0,0,0,0.15);
    font-size: 26px;
    color: #fff;
    line-height: 30px;

    .title-box {
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;

      width: 100%;
      

      * {
        padding: 20px;
      }

      span {
        color: #fff;
        font-weight: 700;
        font-size: 60px;
        line-height: 70px;
        letter-spacing: -2.5px;
        display: block;
      }
    }

    p {
      width: auto;
      padding: auto;
      margin: 0 auto;
      font-weight: 500;
      font-size: 20px; line-height: 30px;
      margin-top: 10px;
    }

    .typing-txt {
      position: relative;
      display: inline-block;
      overflow: hidden;
      font-weight: 500;
      font-size: 60px !important;
      height: 70px;

      border-right: 1px solid #fff;
      white-space: nowrap;
      animation:
        typing-ani 4s steps(27, end) forwards,
        blink-cursor 0.5s step-end infinite;
    }

    @keyframes typing-ani {
      0% { width: 0; }
      100% { width: 900px; }
    }

    @keyframes blink-cursor {
      from, to { border-color: transparent; }
      50% { border-color: #fff; }
    }

  }



}

</style>