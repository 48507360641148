function getTitle (el) {
  const { title } = el.$options
  if (title) {
    return typeof title === 'function'
      ? title.call(el)
      : title
  }
}
export default {
  created () {
    const title = getTitle(this)
    if (title) {
      document.title = title
    }
  }
}