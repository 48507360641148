<template>
  <nav class="main-nav">
    <ul class="navbar">
      <!-- <nav-link link="/" icon="icon_top_home.png">Home</nav-link> -->
      <!-- <nav-link link="/about" icon="icon_top_about.png">About</nav-link> -->
      <li>
        <a href="https://keyupcorporation.com/keyops/" target="_blank">
          About
        </a>
      </li>
      <nav-link link="/video/upload">Create</nav-link>
      <nav-link link="/gallery">Gallery</nav-link>
      <!-- <nav-link link="/signup" icon="icon_top_signup.png">Sign up</nav-link> -->
      
        <nav-link  link="/login" v-if="!isLogin" class="icon-login">Login</nav-link>

        <nav-link link="/logout" v-if="isLogin" class="icon-logout">Logout</nav-link>
 
    </ul>
  </nav>
</template>

<script>
import NavLink from './NavLink.vue';

export default {
  name: 'nav-bar',
  components: {
    NavLink,
  },
  computed: {
    isLogin() {
      return this.$store.state.user !== null;
    }
  }
}
</script>

<style lang="scss" scoped>
.main-nav {
  position: relative;
}

ul.navbar {


  li {
    display: inline-block;
    font-size: 14px;
    letter-spacing: -0.5px;
    margin: 0 30px;
    font-weight: 400;
    a{
      color: #fff;
      line-height: 36px;
      font-weight: 400;
    }

  }

  .icon-login {
    padding: 0px 30px;
    background: linear-gradient(270deg, #E24137 0%, #FE0171 98.48%);
    border-radius: 5px;
    height: 36px;
    line-height: 36px;

    a {
      color:#fff;
      font-weight:500;
      font-size: 14px;
      line-height: 36px;
      display: block;

      span{line-height: 36px;}

    }
  }

  .icon-logout {
    padding: 0px 30px;
    background: linear-gradient(270deg, #E24137 0%, #FE0171 98.48%);
    border-radius: 5px;
    height: 36px;
    line-height: 36px;

    a {
      color:#fff;
      font-weight:500;
      font-size: 14px;
      line-height: 36px;
      display: block;

      span{line-height: 36px;}

    }
  }

}
</style>